import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bf',
        borderBottomWidth: 1,
        fontFamily: 'Helvetica',
        alignItems: 'center',
        height: 150,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,

    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableRow = ({ items, discount , panel,comment }) => {
    var rows = []
    var total_bill = 0
    var counter = 0
    var total_discount = 0

    console.log("InvoiceTableRows items pass from props :")
    console.log(items)
    console.log("InvoiceTableRows discount pass from props :")
    console.log(discount)
    console.log("InvoiceTableRows Panel pass from props :")
    console.log(panel)
    console.log("InvoiceTableRows Comment for testing pass from props :")
    console.log(comment)
   
    if(panel){
        items.map(item => {
            total_bill = total_bill + parseInt(item.Actual_Price)
            total_discount = total_discount + parseInt(item.Actual_Price - item.Test_Price)
          
            counter = counter + 1
            return rows.push(<View style={{
    
                flexDirection: 'row',
                borderTopColor: 'BLACK',
                borderTopWidth: 1,
                alignItems: 'center',
                width: "100%",
                fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{
    
                    // width: '5%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '3%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 6,
                    height: '100%',
    
    
                }}>{counter}</Text>
    
                <Text style={{
    
                    // width: '20%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%',
                    width: '18%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 5,
                    height: '100%',
    
    
                }}>{item.Test_Name}</Text>
                <Text style={{
    
                    // width: '10%',
                    // textAlign: 'center',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 8
                    width: '8%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2
    
    
                }}>{item.Test_Code}</Text>
                <Text style={{
    
                    // width: '25%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // paddingLeft: 8,
                    // height: '100%'
                    width: '18%',
                    
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
    // paddingLeft: 5,

                    paddingLeft: 2.5,
                    paddingRight:2.5,
                    height: '100%',
                    // paddingRight:2
                    textAlign: 'left',
    
    
                }}>
                    {/* {item.Sample_Type} */}
                    {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}

                    </Text>
                 <Text style={{
    
    width: '15.5%',
    
    borderLeftColor: 'black',
    borderLeftWidth: 1,
    // paddingLeft: 5,
    paddingLeft:item.special_comments?2.5: 38.5,
    paddingRight:2.5,
    height: '100%',
    // paddingRight:2
    textAlign: 'left',


}}>
    
    {item.special_comments?item.special_comments:"N/A"}
   
</Text>
                <Text style={{
    
                    // width: '15%',
                    // textAlign: 'left',
                    // borderLeftColor: 'black',
                    // borderLeftWidth: 1,
                    // height: '100%',
                    // paddingLeft: 5,
                    width: '12.5%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
    
                }}>{item.Delivery_Time}</Text>
                <Text style={{
    
                    width: '8.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + parseFloat(item.Actual_Price).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Actual_Price - item.Test_Price)).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Test_Price)).toFixed(2) + "/-"}</Text>
    
            </View>)
        }
        )
        if (items.length < 1000) {
            rows.push(<View>
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    borderTopWidth: 0.5,
                    borderTopColor: 'black',
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
                        padding: 3,
                        borderWidth: 0.5,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Amount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: 5,
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Discount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
                    }}>{"Rs " + parseFloat(total_discount).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    fontStyle: 'bold',
                    marginTop: 5
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
                        borderWidth: 0.5,
    
    
    
                    }}>Net Value</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + (parseFloat(total_bill) - parseFloat(total_discount)).toFixed(2) + "/-"}</Text>
    
                </View>
    
    
            </View>
            )
        } else {
            rows.push(<View style={{
    
                flexDirection: 'row',
                alignItems: 'center',
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: 'black',
                fontStyle: 'bold'
            }} key={"test_1"}>
                <Text style={{
    
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: 8,
                    height: '100%',
    
    
                }}></Text>
            </View>)
        }

    }else{

        items.map(item => {
        
            total_bill = total_bill + parseInt(item.Test_Price)
            counter = counter + 1
            return rows.push(<View style={{
    
                flexDirection: 'row',
                borderTopColor: 'BLACK',
                borderTopWidth: 1,
                alignItems: 'center',
                width: "100%",
                fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{
    
                    width: '3%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 6,
                    height: '100%',
    
    
                }}>{counter}</Text>
    
                <Text style={{
    
                    width: '18%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    paddingLeft: 5,
                    height: '100%',
    
    
                }}>{item.Test_Name}</Text>
                <Text style={{
    
                    width: '8%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2
    
    
                }}>{item.Test_Code}</Text>
                <Text style={{
    
                    width: '18%',
                    
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
    // paddingLeft: 5,

                    paddingLeft: 2.5,
                    paddingRight:2.5,
                    height: '100%',
                    // paddingRight:2
                    textAlign: 'left',
    
    
                }}>

                    {item.Test_Name.toLowerCase().includes("x-ray")?"Patient to visit lab":item.Test_Name.toLowerCase().includes("ultrasound")?"Patient to visit lab through appointment":item.Sample_Type}
                    {/* <Text style={{}}>{item.Sample_Type} */}
                  
                    {/* {comment&&
                <>
                
                <Text style={{
    fontFamily: 'Helvetica-Bold',
   
}}><Text style={{color:"white"}}>-</Text>Special Remarks 
                </Text>
                <Text style={{color:"white"}}>-</Text>{comment}
               </>} */}
                {/* </Text> */}
                
              

                </Text>
                <Text style={{
    
    width: '15.5%',
    
    borderLeftColor: 'black',
    borderLeftWidth: 1,
    // paddingLeft: 5,
    paddingLeft:item.special_comments?2.5: 38.5,
    paddingRight:2.5,
    height: '100%',
    // paddingRight:2
    textAlign: 'left',


}}>
    
    {item.special_comments?item.special_comments:"N/A"}
   
</Text>

                <Text style={{
    
                    width: '12.5%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 2,
    
                }}>{item.Delivery_Time}</Text>
                <Text style={{
    
                    width: '8.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + parseFloat(item.Test_Price).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + ((parseFloat(item.Test_Price) / 100) * discount).toFixed(2) + "/-"}</Text>
                <Text style={{
    
                    width: '8.3%',
                    textAlign: 'center',
                    borderLeftColor: 'black',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    height: '100%',
                    paddingLeft: 5,
    
    
                }}>{"Rs " + (parseFloat(item.Test_Price) - (parseFloat(item.Test_Price) / 100) * discount).toFixed(2) + "/-"}</Text>
    
            </View>)
        }
        )
        if (items.length < 1000) {
            rows.push(<View>
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    borderTopWidth: 0.5,
                    borderTopColor: 'black',
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
                        padding: 3,
                        borderWidth: 0.5,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Amount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + parseFloat(total_bill).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: 5,
                    fontStyle: 'bold'
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
    
    
    
                    }}>Total Discount</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + ((parseFloat(total_bill) / 100) * discount).toFixed(2) + "/-"}</Text>
    
                </View>
                {/* ////////////////////////////////////////////////////////////////////////////////////// */}
                <View style={{
    
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: "100%",
                    fontStyle: 'bold',
                    marginTop: 5
                }} key={"test_1"}>
                    <Text style={{
    
                        width: '30%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%',
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '10%',
                        textAlign: 'center',
                        height: '100%',
                        paddingLeft: 8
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '35%',
                        textAlign: 'left',
                        paddingLeft: 8,
                        height: '100%'
    
    
                    }}></Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'left',
                        color: 'black',
                        borderWidth: 0.5,
    
    
    
                    }}>Net Value</Text>
                    <Text style={{
    
                        width: '12.5%',
                        height: '100%',
    
                        borderWidth: 0.5,
    
    
                        padding: 3,
                        fontFamily: 'Helvetica-Bold',
                        fontSize: 8,
                        textAlign: 'right',
                        color: 'black',
    
    
    
                    }}>{"Rs " + (parseFloat(total_bill) - (parseFloat(total_bill) / 100) * discount).toFixed(2) + "/-"}</Text>
    
                </View>
    
    
            </View>
            )
        } else {
            rows.push(<View style={{
    
                flexDirection: 'row',
                alignItems: 'center',
                width: "100%",
                borderTopWidth: 1,
                borderTopColor: 'black',
                fontStyle: 'bold'
            }} key={"test_1"}>
                <Text style={{
    
                    width: '100%',
                    textAlign: 'left',
                    paddingLeft: 8,
                    height: '100%',
    
    
                }}></Text>
            </View>)
        }
    }




    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow