/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import url from '../../link'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import CallIcon from '@material-ui/icons/Call';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../useWindowDimensions"
import androidLogo from "../../assets/img_stock/New2023/android-logo.png"
import appleLogo from "../../assets/img_stock/New2023/apple-logo.png"
import Logo from "../../assets/img_stock/Jtell/logo.jpeg"





import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <>
   {window.location.href!="https://backup.mpl-labs.pk/ResetPassword" && window.location.href!="https://backup.mpl-labs.pk/FlyDubai" && window.location.href!="https://backup.mpl-labs.pk/AirBlue" && window.location.href!="https://backup.mpl-labs.pk/Login" && window.location.href!="https://backup.mpl-labs.pk/Emirates" && window.location.href!="https://backup.mpl-labs.pk/Register" && window.location.href!="https://backup.mpl-labs.pk/RecallPassword" ?
  <>
    <GridContainer  style={{width:"90%",marginTop:"2%",marginLeft:"5%",marginRight:"5%",}}>
    {/* <GridItem xs={1} sm={6} md={3} lg={3} style={{display:"flex",justifyContent:"center",alignItem:"center",flexDirection:"column",}} >
 <div>
    <h3 style={{fontWeight:'bold',color:"white"}}>Follow Us</h3>
    </div>

      <div>
<LinkedInIcon style={{width:50,height:50,color:"white"}}></LinkedInIcon>
</div>
<div>
<FacebookIcon style={{width:50,height:50,color:"white"}}></FacebookIcon>
</div>
<div>
<TwitterIcon style={{width:50,height:50,color:"white"}}></TwitterIcon>
</div>
     
      </GridItem> */}
    
  
        {/* <GridItem xs={12} sm={6} md={4} lg={4} style={{color:"white",display:"flex",justifyContent:"center",alignItem:"center",flexDirection:"column",marginLeft:width<1250?"0%":"2%"}}>
         <div style={{color:"white",display:"flex",flexDirection:"column"}}>
         <img className='img-fluid m-0 me-2 p-0' src={`${Logo}`} style={{width:'300px',height:'150px'}}></img>

<p style={{ textAlign:"justify",width:"80%"}}>
Jtel is providing superior quality Products to its customers in USA.
</p>                         
                                        </div>
        </GridItem> */}
        {/* <GridItem xs={12} sm={6} md={4} lg={4} style={{marginLeft:width<1250?"0%":"5%"}}>
      <div >

        <h3 style={{fontWeight:'bold',color:"white"}}>Important Links</h3>
<div style={{color:"#faa931"}} >
      <NavLink style={{color:"white"}}  to="/" className={classes.dropdownLink}>
      Home
            </NavLink>
            </div>
            <div >
          <NavLink style={{color:"white"}} to="/Invoice" className={classes.dropdownLink}>
Invoice
</NavLink></div>
<div>

<NavLink  style={{color:"white"}} to="/" className={classes.dropdownLink}>
       
Home 

          </NavLink>
        


          </div>
          <div>

<NavLink  style={{color:"white"}} to="/" className={classes.dropdownLink}>
       
Home 

          </NavLink>
          </div>
          <div>

<NavLink  style={{color:"white"}} to="/" className={classes.dropdownLink}>
       
Home 

          </NavLink>
          </div>
    
                                        </div>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12} lg={4} style={{color:"white"}} >
    
      
      <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
      {/* <h3 style={{fontWeight:'bold'}}>Contact Us</h3> */}
      <BusinessIcon style={{width:25,height:25,color:"white",marginLeft:"3%"}}></BusinessIcon>

      <a> Old Stage RD,Chester,VA 23836- USA</a>
     

     
      </div>
        </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={4} style={{color:"white"}} >
    
      
      <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
      {/* <h3 style={{fontWeight:'bold'}}>Contact Us</h3> */}
     
      <EmailIcon style={{width:25,height:25,color:"white",marginLeft:"3%"}}></EmailIcon>

      <a style={{color:"white"}} href="mailto:info@futurescientific.com.pk">jtelrichmond@gmail.com.</a>
    

     
      </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4} style={{color:"white"}} >
    
      
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
  
    <CallIcon style={{width:25,height:25,color:"white",marginLeft:"3%"}}></CallIcon>

    <a style={{color:"white"}} href="tel:0514577810">
      (804) 9188082</a>

   
    </div>
      </GridItem>
        
      </GridContainer>
     
      </>
     
   :<></>}

 <footer className={footerClasses}>
      <div className={classes.container} style={{color:"white",borderTopColor:"white",borderTopWidth:0.5,borderTopStyle:"solid"}}>
       
         Jtell
            &copy; {1900 + new Date().getYear()}.
            All rights reserved.
      
      </div>
    </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
