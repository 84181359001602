/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import url from '../../link'
import useWindowDimensions from "../../useWindowDimensions";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import Carousel from "react-slick";

import one from "../../assets/img_stock/Jtell/Carousal/oneNewforBottom.jpg"
import two from "../../assets/img_stock/Jtell/Carousal/one.png"
import three from "../../assets/img_stock/Jtell/Carousal/oneNewforBottom.jpg"
import four from "../../assets/img_stock/Jtell/Carousal/oneNewforBottom.jpg"
import five from "../../assets/img_stock/Jtell/Carousal/one.png"
import six from "../../assets/img_stock/Jtell/Carousal/oneNewforBottom.jpg"




import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SlickCarousel() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  var settings = {
    dots: true,
    infinite: true,
    speed: 6000,
    slidesToShow: 4,
    // fade: true,
    slidesToScroll: 4,
    autoplay: true,
    initialSlide: 0,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  
  return (
    <>
 
    
  

 
 <div style={{width:"98%",marginLeft:width<500?"2%":"2%",paddingBottom:"2%"}} >
    {
        width<500?
        <h3 style={{fontWeight:"bold",marginBottom:"3%",textAlign:"center",alignSelf:"center"}}> Satisfield Customers </h3>
:
<h2 style={{fontWeight:"bold",marginBottom:"3%",textAlign:"center",alignSelf:"center"}}> Satisfield Customers </h2>

    }
        <Carousel {...settings}   
        autoplaySpeed={4}
        >
        
       
         
        
         
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30,}}>
          <img height="100%" width="90%" src={one} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={six} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={four} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={four} style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          {/* <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={five} style={{boxShadow:"10px 10px 8px  #9d9d9d",marginTop:"20%"}}></img>

          </div>
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30,border:"1rem solid blue",}}>
          <img height="100%" width="90%" src={two} style={{boxShadow:"10px 10px 8px  #9d9d9d",marginTop:"20%"}} ></img>

          </div> */}
          {/* <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={three} style={{boxShadow:"10px 10px 8px  #9d9d9d",marginTop:"20%"}}></img>

          </div> */}
         
          <div style={{height:"100%",width:"100%",background:"grey",borderRadius:30}}>
          <img height="100%" width="90%" src={three}  style={{boxShadow:"10px 10px 8px  #9d9d9d",}}></img>

          </div>
          {/* <div>
            <h3>6</h3>
          </div>
          <div>
            <h3>7</h3>
          </div>
          <div>
            <h3>8</h3>
          </div> */}
        </Carousel>
      </div>
   
    </>
  );
}


