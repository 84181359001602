// import React from "react";
import React,{useState,useContext,useEffect}from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link,useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js";
import FreeHS_Msg from './FreeHS_Msg'

import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import TeamSectionNew from "./Sections/TeamSectionNew.js";
import SlickCarousel from 'components/SlickCarousel/SlickCarousel';

import WorkSection from "./Sections/WorkSection.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);
export default function Components(props) {
  const { width } = useWindowDimensions();
  const [role, setRole]= React.useState(1)

  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();

  useEffect(() => {
    console.log("hello")
    if(localStorage.getItem('user_id')  ){
      // window.location.href="https://mpl-labs.pk"
      
     }
     else{
      history.push('/Login');  
     }
    // if(localStorage.getItem("role")=="Panel"){
    //   var email=localStorage.getItem("patient_id")
    // var password=localStorage.getItem("id")
    // var role =localStorage.getItem("role")
    // var log='ID'
    // fetch('http://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
    // fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
  
    // method: 'POST',
    //   'Content-Type':"application/json",
    //   body:'vUID=' + email + '&vPW=' + password + '&vRole=' + role + '&vLoginType=' + log + "&vRandom=" + 21211
    // }).then((res)=>res.text()).then( async(resss)=>{
    //   console.log("LabReports Check auto logout"+resss)
     
    //   var res=JSON.parse(resss)
      // if(res.Result){
      //   console.log("Resulte true Login Cridential "+res.Result)
      // }
      // else{
      //   console.log("Resulte false Login Cridential "+res.Result)
      //   alert("Session Expired Please Login Again ..... !")
      //   setTimeout(() => {
      //     localStorage.clear()
      // window.location.href = "https://mpl-labs.pk/Login"
      // // window.location.href = "http://localhost:3000/Login"

          
      //   }, 500);
      // }
     

    
    // })
    // }
//  end 06-june-2023
setRole(parseInt(localStorage.getItem('branch_id')))
  },[])
  
  return (
    <>
    <div style={{
      
      // backgroundColor:"#cb1a2c"
      backgroundColor:"#5794ca"

      }}>
      <Header
        brand="Metropole Laboratories Private Limited"
        //rightLink me headerLink pass kr rhe hn jo show ho rhy hn Home,Services,About Us etc on the base of login patient ,admin etc 
        rightLinks={<HeaderLinks />}
        fixed
        // 21-june -2023
        // color="transparent"
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "black"
        }}
        {...rest}
      />
     {/* <div style={{marginTop:'8em'}}> */}
      {/* when new image recieved 26-aug-2023 */}
      {/* <div style={{width:'100%',
     marginTop:width<500?"6em":width<950?"5.5em":width<1200?"5.0em":width<1290?"6.0em":'7.2em',
    //  backgroundColor:"white"
    }}
     >
     
     <SectionCarousel />
    
       </div> */}
     
    
     <div style={{width:'100%',
    //  marginTop:width<500?"6em":width<950?"5.5em":width<1200?"5.0em":width<1290?"6.0em":'7.2em',
    paddingTop:width<230?"63.5%":width<260?"61.5%":width<290?"59.5%":width<320?"57.5%":width<350?"55.5%":width<410?"57.5%":width<420?"51.5%":width<440?"49.5%":width<460?"48.5%":width<500?"45.5%":width<530?"43.5%":width<550?"41.5%":width<590?"39.5%":width<620?"37.5%":width<650?"35.5%":width<680?"33.5%":width<718?"31.5%":width<740?"30.5%":width<767?"29.5%":width<800?"28.5%":width<850?"26.5%":width<880?"25.5%":width<921?"24.5%":width<950?"23.5%":width<1000?"22.5%":width<1050?"21.5%":width<1100?"20.5%":width<1120?"19.5%":width<1160?"19%":width<1180?"18.5%":width<1220?"18%":width<1253?"17.5%":width<1275?"17%":width<1300?"18.5%":width<1350?"18%":width<1400?"17.5%":width<1440?"17%":"18%",
    paddingBottom:width<230?"63.5%":width<260?"61.5%":width<290?"59.5%":width<320?"57.5%":width<350?"55.5%":width<410?"57.5%":width<420?"51.5%":width<440?"49.5%":width<460?"48.5%":width<500?"45.5%":width<530?"43.5%":width<550?"41.5%":width<590?"39.5%":width<620?"37.5%":width<650?"35.5%":width<680?"33.5%":width<718?"31.5%":width<740?"30.5%":width<767?"29.5%":width<800?"28.5%":width<850?"26.5%":width<880?"25.5%":width<921?"24.5%":width<950?"23.5%":width<1000?"22.5%":width<1050?"21.5%":width<1100?"20.5%":width<1120?"19.5%":width<1160?"19%":width<1180?"18.5%":width<1220?"18%":width<1253?"17.5%":width<1275?"17%":width<1300?"18.5%":width<1350?"18%":width<1400?"17.5%":width<1440?"17%":"18%",
   
    backgroundColor:"white"
    }}
     >
      {/* {localStorage.getItem('admin_rights')=="true"?
         <div style={{width:"100%", 
         position:"fixed"

         }}>
        <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={role}
          onChange={(e)=>{
       setRole(e.target.value)
       console.log(e.target.value)
       localStorage.setItem('branch_id',e.target.value)

          }}
        >
        <MenuItem  value={1}>JTEL</MenuItem>
          <MenuItem value={2}>ROANOKE</MenuItem>
         
        </Select>
      </FormControl>
      </div>
      :<></>}
      */}
     <h1 style={{color:"white"}}>Jtell</h1>
    {/* {width} */}
       </div>
     
    
   
{/* these classes.main are call from import styles from "assets/jss/material-kit-react/views/components.js"; */}
      {/* <div className={classNames(classes.main, classes.mainRaised)}
       style={{marginTop:width<500?-25:width<600?-30:width<700?-40:width<800?-50:width<900?-50:width<1000?-60:width<1100?-70:width<1200?-80:-90}}
       >
          <ProductSection />
     

      </div> */}
      <Footer />

    </div>
</>
  );
}
