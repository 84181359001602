import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';


import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import COVID19_PCR from '../../COVID19_PCR/Covid19_PCR.js'
import Emirates from './AirLineSection.js'
// 26-june-2023
import AQ from "../../../assets/img_stock/New2023/icon/Icon1.png"
import CP from "../../../assets/img_stock/New2023/icon/Icon3.png"
import GSS from "../../../assets/img_stock/New2023/icon/Icon2.png"
import IL from "../../../assets/img_stock/New2023/icon/Icon4.png"
import AD from "../../../assets/img_stock/New2023/icon/Icon5.png"
import PCS from "../../../assets/img_stock/New2023/icon/Icon6.png"
//6-jul-2023
import useWindowDimensions from "../../../useWindowDimensions";









import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.section} style={{padding:width<500?"0%":"10px 0",backgroundColor:"#fbfbfb"}}>
      {/* <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12} style={{marginLeft:"2%",marginRight:"2%"}}>
          
          <h2 className={classes.title}>About Us</h2>
        
      
        </GridItem>
        </GridContainer>
        */}
            {/* <COVID19_PCR /> */}

     
      
     {/* <br /> */}

        <GridContainer style={{marginLeft:"1%",marginRight:"1%",}} >
        {/* <GridItem xs={12} sm={12} md={6} lg={3}>
            <InfoArea
              title="One"
              description="Drug Regulatory Authority of Pakistan (DRAP),Drug Sale License (PPC),ISO 9001:2015, Pakistan Software Export Board (PSEB)"

              icon={AD}
              iconColor="danger"
              vertical
            />
          </GridItem> */}
          <GridItem xs={12} sm={12} md={6} lg={4}>
            <InfoArea
              title=" Mission"
              description=" Serving as a one-stop destination, we strive to provide a convenient and comprehensive experience for our customers, offering a curated selection of vape products, premium tobacco, and quality fuel at our gas station"

              icon={GSS}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12}  md={6} lg={4}>
            <InfoArea
              title="Vision"
              description="As a leading convenience hub, we envision becoming the preferred choice for vape enthusiasts, tobacco connoisseurs, and travelers seeking top-notch products and services, setting a new standard in the convergence of retail and fuel offerings."
              icon={AD}
              iconColor="success"
              vertical
            />
          </GridItem>
         
         
        
          <GridItem xs={12} sm={12}  md={6} lg={4}>
            <InfoArea
              title="Best Pricing"
              description="
              Dedicated to delivering exceptional value, our commitment is to provide the best pricing across a diverse range of products and services, ensuring our customers consistently enjoy cost-effective choices"

              icon={CP}
              iconColor="info"
              vertical
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={6} lg={3}>
            <InfoArea
              title="Five"
              description="Drug Regulatory Authority of Pakistan (DRAP),Drug Sale License (PPC),ISO 9001:2015, Pakistan Software Export Board (PSEB)"

              icon={AQ}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <InfoArea
              title="Six"
              description="Drug Regulatory Authority of Pakistan (DRAP),Drug Sale License (PPC),ISO 9001:2015, Pakistan Software Export Board (PSEB)"

              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12}  md={6} lg={3}>
            <InfoArea
              title="Seven "
              description="Drug Regulatory Authority of Pakistan (DRAP),Drug Sale License (PPC),ISO 9001:2015, Pakistan Software Export Board (PSEB)"
              icon={AQ}
              iconColor="success"
              vertical
            />
          </GridItem>
         
         
        
          <GridItem xs={12} sm={12}  md={6} lg={3}>
            <InfoArea
              title="Eight"
              description="Drug Regulatory Authority of Pakistan (DRAP),Drug Sale License (PPC),ISO 9001:2015, Pakistan Software Export Board (PSEB)"

              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          */}
         
          
        
        </GridContainer>

        <br />
        {/* aik me he kr dya hai phly 3 ,3 alag thy now 6 in one */}
        {/* <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Assured Quality"
              description="We participate in internationally monitored external quality assurance programs along with a robust daily QC monitoring system to ensure the accuracy and reliability of every service we provide."
              icon={AQ}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Competitive Prices"
              description="MPL offers exceptional quality of services at competitive and affordable prices which are usually 20 to 25% lesser from other diagnostic service providers, offering best value against the fee you pay."
              icon={CP}
              iconColor="danger"
              vertical
            />
          </GridItem>
         
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Patient-Centered Services "
              description="We are dedicated to earning the trust and loyalty of our patients by prioritizing their comfort and satisfaction. Our passion for ensuring that every patient receives the highest quality of care and support drives us to continuously strive for excellence."
              icon={PCS}
              iconColor="success"
              vertical
            />
          </GridItem>
        
        </GridContainer> */}
         {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%',marginTop:"5%"}}>
          <img
                src={require("assets/img/building.jpeg")}
                style={{ 
                maxWidth: "60%",
                maxHeight:'30%',
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer> */}
     
    </div>
  );
}
