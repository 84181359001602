import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// import Button from "components/CustomButtons/Button.js";
//6-jul-2023
import useWindowDimensions from "../../../useWindowDimensions";



// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img_stock/New2023/Department/Ultrasound.jpg";

import team2 from "assets/img_stock/New2023/Department/Echocardio.jpg";
import team3 from "assets/img_stock/New2023/Department/Fibroscan.jpg";
import team4 from "assets/img_stock/New2023/Department/DigitalXRaymachine.jpg";
import team5 from "assets/img_stock/New2023/Department/OPG.jpg";
import team6 from "assets/img_stock/New2023/Department/Spirometry.jpg";
import SlickCarousel from "components/SlickCarousel/SlickCarousel";

const useStyles = makeStyles(styles);

export default function TeamSectionNew() {
  const { width } = useWindowDimensions();

  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section} style={{}}>
      {/* <h2 className={classes.title}>Our Imaging & Radiology Services</h2> */}
  
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
            <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>
                <img src={team1} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              ULTRASOUND
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Ultrasound and Color Doppler are performed by highly qualified Sonologists, using state-of-the-art equipment to produce high-quality images that aid in the accurate diagnosis of a wide range of medical conditions.
                </p>
              </CardBody>
              <NavLink to="/Ultrasound" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>

           
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team2} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              ECHOCARDIOGRAPHY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Echo (Echocardiography) under supervision of classified Cardiologists, using state-of-the-art equipment, provides accurate and timely assessment of heart function to identify various heart conditions.
                </p>
              </CardBody>
              <NavLink to="/Echocardiography" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team3} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              FIBROSCAN
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Fibroscan services at MPL are offered by highly qualified
and expert Sonologists & Gastroenterologists. This non-invasive, quick and accurate diagnostic tool for liver fibrosis and monitoring liver health.
                </p>
              </CardBody>
              <NavLink to="/Fibroscan" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
       
          <GridItem xs={12} sm={12} md={4} style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
            <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>
                <img src={team4} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              DIGITAL X-RAY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                Metropole Laboratories offers digital X-Ray services using
state-of-the-art & latest technology of +500MA X-Ray
generator with digital CR & printers, providing quick and
efficient imaging services.
                </p>
              </CardBody>
              <NavLink to="/DigitalXray" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>

           
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team5} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              OPG (Orthopantomogram)
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}  style={{color:"black",textAlign:"justify"}}>
                machines from Yoshida Japan to provide high-quality 2-D
images by team of highly qualified and skilled radiologists
to provide accurate and reliable interpretation of the
findings of Orthopantomogram.
                </p>
              </CardBody>
              <NavLink to="/OPG" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={4}  style={{display:"flex",justifyContent:"center",alignIttem:"center",minHeight:"400px",marginTop:"5%"}}>
          <div plain style={{width:"90%",borderWidth:"5px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} style={{marginTop:"4%"}}>

                <img src={team6} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              SPIROMETRY
                <br />
               </h4>
              <CardBody>
                <p className={classes.description} style={{color:"black",textAlign:"justify"}}>
                We offer advanced Spirometry services to diagnose
respiratory conditions through our highly qualified
pulmonologists using the latest technology to provide
accurate and reliable results.
                </p>
              </CardBody>
              <NavLink to="/Spirometry" >
              <Button color="#196c90" size="sm" style={{backgroundColor:'#196c90',marginBottom:"5%"}}>See More</Button>
              </NavLink>
              </div>

          </GridItem>
        </GridContainer> */}
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
          <Card plain>


              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require('assets/img_stock/Molecular_Genetics.JPG')} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Clinical Pathology & Microbiology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Clinical pathology & Microbiology department is equipped with Cobas U411 for urine analysis, fully automated Sperm health analyzer by Biola Russia , Automated  blood culture system  by BD USA. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/histopathology_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Histopathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our panel of highly qualified histopathologists are helping the clinicians & surgeons by making tissue diagnosis for better patient care and manageability
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/moleculer_dep_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Biology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Molecular biology department is equipped with Cepheid Gene expert molecular system for the detection and viral loads of many viral & Bacterial pathogens including Hepatitis, B , Hepatitis C , HIV &  Mycobacterium Tuberculosis.  Our Gene expert system also provides Drug resistance to Tuberculosis.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer> */}
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/pathology_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Special Pathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our special pathology department we are performing Cytogenetic studies, Platelet aggregation studies, Interferon gamma release assay ( T SPOT TB Test) for the accurate diagnosis of Tuberculosis , Immunoflourescence studies for Anti-nuclear antibodies, Urea Breath test for accurate diagnosis of H.Pylori infections, Hydrogen Breath Tests for the intolerances of different sugars.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/animations/virus (12).png")} alt="..." 
                  alt="..."
                  width={"70%"}
                  className={classes.imgFluid+" "+"funfact-one__virus-1"}
                />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Covid 19 PCR Lab
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                MPL has established a dedicated Covid-19 PCR testing Laboratory. This  BSL-2 Covid lab is equipped with state of the art PCR systems by Hibergene diagnostics Ireland. The high quality CE marked and IVD approved kits are being used for testing which are also made by Hibergene Diagnostics Ireland. Currently we have a PCR testing capacity of 2000 per day.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/molecular_gen_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Genetics
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our molecular genetics department is lead by Dr.Hani Akbar, a well-known clinical geneticist. We provide counseling and diagnostic facilities of genetic disorders to Patients and clinicians. We are collaborating with different international laboratories for outsource testing of genetic markers.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
           
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/x-ray.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Imaging & Radiology Department
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our Imaging & Radiology department we are providing digital X-Ray & Ultrasound facilities to our customers with the help of our highly qualified panel of Radiologists and sonologists . We are equipped with latest X-Ray and ultrasound machines to meet the clinician’s needs. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
         
          </GridItem>
        </GridContainer> */}
          {/* <GridContainer  style={{marginTop:"5%"}}>
          <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
          <h2 className={classes.title}>Our Licenses & Certifications</h2>
           </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%',marginTop:"5%"}}>
       <SlickCarousel/>      
          </GridItem>
          </GridContainer> */}
        <GridContainer style={{}} >
          <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
            {width<500?
          <h3 className={classes.title}>Our Prime Location</h3>
        :
        <h2 className={classes.title}>Our Prime Location</h2>
      }
           </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%',marginTop:"2%",marginLeft:"2%",marginRight:"2%"}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3171.6312949539647!2d-77.40255481804468!3d37.35123676451127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b1088c68919473%3A0x22ee7ff465cc3b5b!2s12910%20Old%20Stage%20Rd%2C%20Chester%2C%20VA%2023836%2C%20USA!5e0!3m2!1sen!2s!4v1705304871756!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.798494683826!2d73.12059897515762!3d33.61053244092565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeb340fba6ffb%3A0x3905f8dcd40f6fd9!2sFuture%20Scientific!5e0!3m2!1sen!2s!4v1703332786267!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53115.91986215992!2d73.026057615748!3d33.68966667301035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf43bd76f797%3A0xada34418cc053a99!2sMetropole%20Laboratories%20Private%20Limited!5e0!3m2!1sen!2s!4v1598854374278!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" /> */}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41516.5177557508!2d73.72606532745955!3d33.864797396349395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e06be2b85ad0ff%3A0xf354815a27ce05d7!2sCMH%20Rawalakot!5e0!3m2!1sen!2s!4v1689934580006!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>  */}
          </GridItem>
          </GridContainer>
          {/* for branchese */}
          {/* <GridContainer style={{marginTop:"5%"}} >
          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid} >
          <h2 className={classes.title}>Our Branches </h2>
           </GridItem>
           
           <GridItem xs={12} sm={12} md={6}  style={{display:"flex",justifyContent:"center",alignIttem:"center",marginTop:"5%"}}>
      <div style={{width:"70%",backgroundColor:"#e4e4e4",minHeight:"250px",display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column",borderWidth:"3px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>
       <h3 style={{fontWeight:"bold" }}>Qila Didar Singh Collection Center</h3> 
       <p style={{color:"black"}}>Tanki wala gala, Near Rural Health
Center, Qila Didar Singh <br></br> (Gujranwala)</p>   
<p style={{color:"black"}}>
+92 317 333 6630</p>
</div>
       
          </GridItem>
          <GridItem xs={12} sm={12} md={6}  style={{display:"flex",justifyContent:"center",alignIttem:"center",marginTop:"5%"}}>

          <div style={{width:"70%",backgroundColor:"#e4e4e4",minHeight:"250px",display:"flex",justifyContent:"center",alignIttem:"center",flexDirection:"column",borderWidth:"3px",borderColor:"#18688a",borderStyle:"solid",borderRadius:"15px",boxShadow:"10px 10px 8px  #9d9d9d",}}>

       <h3 style={{fontWeight:"bold"}}>Rawalakot Collection Center</h3> 
       <p style={{color:"black"}}>Al Fahad Plaza, Near UK Hotel,
CMH Road,<br></br> Rawalakot, AJK.
</p>   
<p style={{color:"black"}}>
+92 317 333 6640, 058 244 459 59</p>
</div>
       
          </GridItem>
          </GridContainer> */}
      </div>
  
  );
}
