// import React, { useEffect } from "react";
import React,{useState,useContext,useEffect}from 'react'
import { ProductContex } from '../../ContextStore';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { Link ,useHistory} from "react-router-dom";
import image1 from "assets/img_stock/Jtell/logo.jpeg";



// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Close from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import TextField from '@material-ui/core/TextField';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";

import Input from '@material-ui/core/Input';

import url from '../../link'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import image from "assets/img/building.jpeg";
import image from "assets/img_stock/Jtell/logo.jpeg"


import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function LoginPage(props) {

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const {dispatcchUserEvents,billAmount,billAmount2} = useContext(ProductContex);

  const [classicModal, setClassicModal] = React.useState(false);
  const [email, setEmail]= React.useState('')
  const [password, setPassword]= React.useState('')
  const classes = useStyles();
  const [role, setRole]= React.useState('JTEL')
  const [ServerResponse, setServerResponse]= React.useState('')
  const { ...rest } = props;
  const [branches,setBranches]=React.useState([])
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    newpassword:'',
    shownewpassword:false,
    confirmPass:'',
    showConfrimPass:false

  });
  const history = useHistory();
  const handleClickShowConfrimPass = () => {
    setValues({ ...values, showConfrimPass: !values.showConfrimPass });
  };

  const handleMouseDownConfrimPass= (event) => {
    event.preventDefault();
  };

  useEffect(()=>{
    if(localStorage.getItem('user_id')  ){
      // window.location.href="https://mpl-labs.pk"
      history.push('/Home');  
     }
      //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
      //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
       
      // setBranches(response)
         
      //  })
       window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    
  },[])
  return (
    <div>
      {/* <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        login="yes"
        rightLinks={<HeaderLinks />}
        {...rest}
      /> */}
      <div
        className={classes.pageHeader}
        style={{
          // backgroundImage: "url(" + image + ")",
          // backgroundSize: "cover",
          // backgroundPosition: "center bottom",
          backgroundColor:"#280508"
        }}
      >
        <div className={classes.container} style={{paddingBottom:"25px"}}>
          <GridContainer justify="center" style={{marginTop:15}}>
            <GridItem xs={12} sm={12} md={4} >
              <Card className={classes[cardAnimaton]} 
              style={{borderColor:"#cb1a2c",borderStyle:"solid",borderWidth:"3px"}}
              >
                <form className={classes.form} >
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:"white",borderColor:"#cb1a2c",borderStyle:"solid",borderWidth:"3px"}}>
                    {/* <h4 style={{color:'white'}}>Login</h4>
                     */}
      <img src={image1} style={{height:100,width:250}} ></img>
                    
                  </CardHeader>
                  <CardBody>
                  {/* <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={role}
          onChange={(e)=>{
       setRole(e.target.value)
          }}
        >
        <MenuItem  value={"JTEL"}>JTEL</MenuItem>
          <MenuItem value={"ROANOKE"}>ROANOKE</MenuItem>
         
        </Select>
      </FormControl> */}
       {/* <h4 style={{color:'#cb1a2c'}}>Login</h4> */}
                    
      {/* <br /> */}
      
      {/* <br /> */}
                  <TextField required id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="UserName / ID"
                  value={email}
                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }} 
                  
                  />
                  
                  <br />
                  <br />
                    <Input required id="" block
                  fullWidth={true}
                  label="Password"
                  value={password}
                  type={values.showConfrimPass ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfrimPass}
                        onMouseDown={handleMouseDownConfrimPass}
                      >
                        {values.showConfrimPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e)=>{
                    setPassword(e.target.value)
                  }} 
                  
                  />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#196c90" size="lg" style={{backgroundColor:'#cb1a2c'}}
                     onClick={() => {
                       if(email.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Please Enter Login Name !!')
                        return
                    
                       }
                       if(password.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Please Enter Password !!')
                        return
                    
                       }
                       var log='ID'
                      // fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
                      // fetch('https://70.35.203.150:443/4DACTION/WebUserLogin',{
                      fetch('https://4dserver.jtelwholesale.com/4DACTION/WebUserLogin',{

                    
                      method: 'POST',
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                          user_type:"JTEL",
                          user_name:email,
                          user_password:password
                        })
                      }).then((res)=>res.json())
                      .then( async(response)=>{
                        console.log(JSON.stringify(response))
                        var con=false

                        if(response[0].user_id!=""){
                              localStorage.setItem('valid_token',response[0].valid_token)
                          localStorage.setItem('branch_id',response[0].branch_id)
                          localStorage.setItem('user_id',response[0].user_id)
                          localStorage.setItem('user_name',response[0].user_name);
                          localStorage.setItem('admin_rights',response[0].admin_rights);
                          localStorage.setItem('If_allow_price_change',response[0].If_allow_price_change);
                          localStorage.setItem('If_allow_discount',response[0].If_allow_discount);
                          // localStorage.setItem('If_allow_price_change',con);
                          // localStorage.setItem('If_allow_discount',con);

dispatcchUserEvents("LoginCred",{token:response[0].valid_token,branchid:response[0].branch_id,userId:response[0].user_id,userName:response[0].user_name,adminRight:response[0].admin_rights})
                      history.push('/Home');              

                          
}
else{
  // alert("Please Enter Valid Credentials")
  setClassicModal(true)
  setServerResponse('Please Enter Valid Credentials !!')
}

                      })
                      //   console.log(resss)
                      //   var res=JSON.parse(resss)
                      //   if(res.Result){
                      //     localStorage.clear()
                      //     if(role=='Admin'  && !res.Name.toLowerCase().includes('mpl-') && res.IF_Authorized=="false"){
                      //       setServerResponse('Invalid Credentials !')
                      //       setClassicModal(true)
                      //       return
                      //     }
                      //     setServerResponse('Login Successfully!')
                      //     localStorage.setItem('user_id',res.User_ID)
                      //     localStorage.setItem('patient_id',res.ID)
                      //     localStorage.setItem('patient_name',res.Name)
                      //     localStorage.setItem('gender',res.Gender);
                      //     //06-june-2023 for auto logout when password change 2nd part in labReports
                      //     if(role=="Panel"){
                      //     localStorage.setItem('id',password)
                      //     }

                      //     var age=res.Age
                      //     var year=age.split("Y")
                      //     var y=year[0]
                      //     var name=res.Name
                      //     var branchID=res.Branch_ID

                      //     if(name.toLowerCase().includes('mpl-')){
    
                      //       var branch=name.toLowerCase().split('mpl-')[1]
                      //       localStorage.setItem('branch',branch)
                      //       localStorage.setItem('branchID',branchID)
                           
                         
                      //   }else{
                          
                      //      localStorage.setItem('branch','false')
                            
                      //   } 
                      //   localStorage.setItem('refresh_portal',true)
                      //     localStorage.setItem('age',y); 
                      //   if(res.Mobile !=null && res.Mobile!=undefined){
                      
                      //   localStorage.setItem('mobile',res.Mobile)
                      //   }else{
                          
                      //   localStorage.setItem('mobile',"")
                      //   }
                      //   if(res.eMail !=null && res.eMail!=undefined){
                          
                      //     localStorage.setItem('email',res.eMail)
                      //     }else{
                            
                      //     localStorage.setItem('email',"")
                      //     }
                      //     localStorage.setItem('patient',true)
                      //     localStorage.setItem('role',role)
                         
                      //     setClassicModal(true)
                         
                      //     setTimeout(()=>{
                      //     window.location.href=url+"/Lab_Reports"
                      //     },1000)
                          
                      //   }
                      //   else{
                      //     setServerResponse('Invalid Credentials !')
                      //     setClassicModal(true)
                      //   }
                      // })  
// dispatcchUserEvents("ClintId_Set",res.client_id)
// console.log("billAmount for testing"+billAmount)
// dispatcchUserEvents("Check","testing")
// console.log("billAmount 2 for testing"+billAmount2)


                      // history.push('/Home');              
                      }}
                    >
                      Login
                    </Button>
                    
                  </CardFooter>
                </form>
                {/* <Button simple size="lg" >
                <Link to="/RecallPassword" 
style={{color:"#cb1a2c"}}
>
                      Forgot Password ?
                      </Link>
                    </Button> */}
                    {/* <Button
           simple size="lg" color="primary"
          
        >
          <Link to="/Register" 
          style={{color:'black'}}
        
          >REGISTER YOUR SELF AS PATIENT</Link>
        </Button> */}
              </Card>
            </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Login</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                   {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>
         
        </div>
      </div>
    </div>
  );
}
