import React, { useEffect, Fragment,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
//24-march-2023 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import useWindowDimensions from "../../useWindowDimensions";


// import Grid from "@material-ui/core/Grid";


import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { NavLink } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";



import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
// import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
// import CovidTemplate from './Covid_PDF/CovidTemplate'
// import TemplateCertificate from './TemplateCertificate/TemplateCertificate'
import GroupIcon from '@material-ui/icons/Group';
import RemoveRedEyeOutlinedIcon from '@material-ui//icons/RemoveRedEyeOutlined';
// import CertificateTemplate from './Certificate/CertificateTemplate'

// import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'
import { bool } from 'prop-types';
import { ProductContex } from '../../ContextStore.js';


import TextField from '@material-ui/core/TextField'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""
var invoice = id.toLowerCase().includes('mpl-') ? [
]
  : [
    { id: 'certificate', label: 'Certificate' },
    // { id: 'invoice', label: 'View Invoice' },
    { id: 'refresh', label: 'Refresh Report' }
  ]

var ksaInfo= localStorage.getItem('patient_id')=="AR-01033" ?
[
  { id: 'Airline', label: 'Airline' },
  { id: 'Airport', label: 'Airport' }
]:
[
  { id: 'code', label: 'Test Code' },
  { id: 'branch', label: 'Branch' }
]
  //21-march-2023 invoices show for panel and patient

var panelInvoice= localStorage.getItem('role')=="Panel" ?
[
    { id: 'invoice', label: 'View Invoice' },

]:[]


// const columns =
//   localStorage.getItem('role') == 'Admin' ?
//     [
//      { id: 'mr_number', label: 'MPL MR No' },
//       { id: 'id', label: 'MPL ID' },
//      //18-feb-2023 
//      { id: 'reference_no', label: 'Panel Ref No' },
//     { id: 'name', label: 'Patient Name' },
//     { id: 'title', label: 'Test Name' },
//     { id: 'Rep_TimeDate', label: 'Reporting Time' },
//     { id: 'branch', label: 'Branch' },
//     { id: 'status', label: 'Status' },
//     { id: 'download', label: 'View Single Test Report' },
//     { id: 'viewAllReports', label: 'View All Tests Report' },

//     ...invoice,

//     ]
//     :
//     [
//     { id: 'mr_number', label: 'MPL MR No' },
//     { id: 'id', label: 'MPL ID' },
//     //18-feb-2023 
//     { id: 'reference_no', label: 'Panel Ref No' },


//     { id: 'name', label: 'Patient Name' },
//     { id: 'title', label: 'Test Name' },
//     { id: 'Rep_TimeDate', label: 'Reporting Time' },
//     ...ksaInfo,
//     { id: 'status', label: 'Status' },
//     { id: 'download', label: 'View Single Test Report' },
//     { id: 'viewAllReports', label: 'View All Tests Report' },
//     ...panelInvoice


//     ]



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests({}) {
  const {dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount} = useContext(ProductContex);

  const classes = useStyles();
  const { width } = useWindowDimensions();

  const [CategoriesList, setCategoriesList] = React.useState([])
  const [DoctorNameList, setDoctorNameList] = React.useState([])
  const [ProductList, setProductList] = React.useState([])
  const [SelectedProducts, setSelectedProducts] = React.useState([])
  const [remarks,setremarks]=React.useState("")
  const [specialDiscountPer,setSpecialDiscountPer]=React.useState(0)
  const [specialDiscountValue,setSpecialDiscountValue]=React.useState(0)
  // for refund special discount
  const [rfdSpecialDiscountPer,setrfdSpecialDiscountPer]=React.useState(0)
  const [rfdSpecialDiscountValue,setrfdSpecialDiscountValue]=React.useState(0)
  //for count data 
  const [count,setcount]=React.useState(1)
  // dialoge box
  const [DialogBoxOpen,setDialogBoxOpen]=React.useState(false)
  //client list 
  const [ClientList,setClientList]=React.useState([])
  const [open,setOpen]=React.useState(false)
  // for image View below line 3-feb-2024
  const [openImageView,setopenImageView]=React.useState(false)
  const [systemId,setsystemId]=React.useState()
  const [selectedReport,setSelectedReport]=React.useState()
  const [imageloading,setimageloading]=React.useState(false)
  


  var tesTmg= "data:image/png;base64,";


  const history = useHistory();




  




  var from = new Date();

  useEffect(()=>{
console.log("yess")
if(localStorage.getItem('user_id')  ){
  // window.location.href="https://mpl-labs.pk"
  
 }
 else{
  history.push('/Login');  
 }
  },[cartItems])

  const [columns, setColumns] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', 
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    },
    {
      // title: 'Stock ', field: 'stock', 
      title: 'Stock', field: 'netvalue', type: 'numeric', editable: 'never',
      render: rowData => {
       return <p style={{ color: "#cb1a2d", fontWeight: "bold",fontSize:20 }}>{rowData.stock}</p>
     }
      
    },
    {
      title: 'View', field: 'viewimage', 
      
    }
  ]);
  // cond lgae hai ager login pe if discount true hai to disc column edit ho sky
  const [columnsNewForCondiotion, setcolumnsNewForCondiotion] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    },
    {
      title: 'View', field: 'viewimage', 
      
    }
  ]);
  const customCellStyle = (rowData) => {
    // Define your condition to change row color
    return {
      backgroundColor: rowData.age > 28 ? 'lightcoral' : 'lightgreen',
    };
  };
  const [data, setdata] = React.useState([
    {
    sn:1,
    code:"ABC",
    itemName:"abc",
    rate:20,
    qty:10,
    rfd:10,
    amount:100,
    discount:100,
    netvalue:100
  }])

  useEffect(()=>{
dispatcchUserEvents("ClearData")

    
    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",
    fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

      
        user_id:localStorage.getItem('user_id')
      })
    }).then((res) => res.json()).then((response) => {
    // console.log("check response "+JSON.stringify(response))  
    setCategoriesList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    })
    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",
    fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),
      
        user_id:localStorage.getItem('user_id')
      })
    }).then((res) => res.json()).then((response) => {
    // console.log("check Clients data "+JSON.stringify(response))  
    // setClientList(response)
    var data = [];
    response.map((item) => {
      // console.log(item)
     
      var obj = {
        clientTitle: item.arr_cl_name,
        phone: item.arr_phone,
        city: item.arr_city,
        state: item.arr_state,
        zip: item.arr_zip,
        address: item.arr_address,

        select: (
          <Button
            variant="contained"
            color="primary"
            style={{
            backgroundColor:"#cb1a2d"

            }}
            className={classes.button}
            onClick={() => {
              localStorage.setItem('client_id',item.arr_cl_id)
              localStorage.setItem('client_Title',item.arr_cl_name)
              // console.log("obj "+  client_id:item.arr_cl_id,)
              // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientSpecDiscPerCallsUp_api",
              fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientSpecDiscPerCallsUp_api",

              {
                method:"POST",
                'Content-Type':"application/json",
                body: JSON.stringify({
                  // branch_id:1,
                  client_id:"01CL00575",
                
                  user_id:localStorage.getItem('user_id')
                })
              }).then((res) => res.json()).then((response) => {
console.log("check list of client spec price"+JSON.stringify(response))
              })

          // setId(item.Patient_ID)
          // setName(item.Patient_Name)
          // setMobile(item.MobileNo)
          setOpen(false)
            }}
          >
            Select
          </Button>
        ),
      };

      data.push(obj);
    });
    setClientList(data);
    // setOpen(true);
    // setCategoriesList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    })
    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",
    fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

        category_id:0,
        user_id:localStorage.getItem('user_id')

      })
    }).then((res) => res.json()).then((response) => {
    console.log("check Products response "+JSON.stringify(response))  
    setProductList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    }).catch((err)=>{
      console.log("err "+err)

     alert("Session Expired "+err)
     localStorage.clear()
//       if(err.includes("Invalid User ID")){
// localStorage.clear()

//       }
    })
  },[])
//  1-sep-2024
const fetchStockBalance = async (newValue) => {
  const obj = {
    system_id: newValue.system_id,
    user_id: localStorage.getItem('user_id')
  };
  console.log("object")
console.log(obj)
const res =  await fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductSockBalanceCallsUp",
                    
                       {
                         method:"POST",
                         'Content-Type':"application/json",
                         body: JSON.stringify(obj)
                       }).then((res) => res.json()).then((response) => {
                      // alert(response)
                        console.log(response)
                        return response[0].stock_balance
                   
                       
                       })
                       .catch((err)=>{
                        console.log(err)
                   

                       })
                       console.log(res)
                       return res
 
};
  
  return (
    <>
      <Paper className={classes.root} style={{}}>
      {/* <h1 style={{color:"red"}}> hshshsahhdfahdsajhsdfagh</h1> */}
        <div style={{ padding: '1em'}}>

          {/* <h3>Status</h3> */}
          {/* <h3 style={{color:"black",fontWeight:"bold",marginLeft:"1%"}} >
  
   Invoice Module
   
              </h3> */}
                <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{marginLeft:width<500?0:-10}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setOpen(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
<GroupIcon style={{width:20,height:20,color:"white",marginRight:5}}></GroupIcon>
               Select Clients
           </Button>
           <div>
           {localStorage.getItem('client_Title')?
           <p style={{fontWeight:"bold",marginLeft:"2%"}}>
            Client :
           {localStorage.getItem('client_Title')}</p>
            :<></>}
            </div>
            </GridItem>
            
            </GridContainer>
           
          <GridContainer style={{marginTop:width<500&&localStorage.getItem('client_Title')?"2%":width<500?"4%":"1%"}}>
            <GridItem xs={12} sm={12} md={4} style={{marginLeft:width<500?"0%":"1%"}}>
         
            <Autocomplete
                  id="Ref_By_Doctor"

                  options={ProductList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.item_name + ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em',
                    marginLeft:width<500?"0em ":width<960?"4px": '0em',

                    
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={async(event, newValue) => {
                    console.log("check cartItem"+cartItems)

                    if (newValue != null) {

                

                      const stock = await fetchStockBalance(newValue);
                      console.log("value in stock ")
                      console.log(stock)

                      // end 
                      // setRef_By_Dr(newValue.DoctorID)
                      console.log("Selected Product"+newValue.system_id)
                      // setSelectedProducts(newValue)
                     
                      var obj1 =
                  {
                    sn:count,
                    systemId: newValue.system_id,
                    code: newValue.item_code,
                    itemName: newValue.item_name,
                    rate:newValue.selling_price,
                    qty:1,
                    rfd:0,
                    rfdAmount:0,
                    amount:newValue.selling_price,
                    disc:0,
                    discount:0,
                    netvalue:newValue.selling_price,

                    buyingPrice:newValue.buying_price,
                    stock:stock,
                    viewimage:( 
                    <RemoveRedEyeOutlinedIcon
                    style={{color:"#5794ca",
                  
                  marginTop:-10}}
                    onClick={() => {
                      setopenImageView(true)
                      setimageloading(true)
                        var obj={
    // branch_id:1,
    system_id:newValue.system_id,
  
    user_id:localStorage.getItem('user_id')
  }
  console.log("check obj"+JSON.stringify(obj))
                       fetch("https://4dserver.jtelwholesale.com/4DACTION/WebPictureCallsProductSingle",

                       {
                         method:"POST",
                         'Content-Type':"application/json",
                         body: JSON.stringify(obj)
                       }).then((res) => res.json()).then((response) => {
                        if(response[0].request_status=="No Picture"){
                          setopenImageView(false)
                          alert("No Picture Found !")
                      setimageloading(false)
                     

                          return
                        }
                         console.log("response "+JSON.stringify(response))
                         setSelectedReport(`${tesTmg}`+response[0].picture_image)
                      setimageloading(false)

                        
                        
                       })
                       .catch((err)=>{
                        console.log(err)
                      setimageloading(false)

                       })
                      // setsystemId(newValue.system_id)
                     
                    
                    }}
                    ></RemoveRedEyeOutlinedIcon>
                    // <Button
                    // variant="contained"
                    // color="primary"
                    // style={{
                    // backgroundColor:"#cb1a2d"
        
                    // }}
                    // className={classes.button}
                    // onClick={() => {alert("hello")}}></Button>
                    ),
                     
                  }
                  
                  // var all_test = [...SelectedProducts, obj1]
                  // var hold = await all_test.map((row) => {

                  // })
//                   Promise.all(all_test).then((values) => {
//                     setProductList(values)
// console.log("chekc one by one "+JSON.stringify(values))
//                   })


console.log("check obj"+JSON.stringify(obj1))
dispatcchUserEvents("ADD_To_CART",{data:obj1,requestedQty:1})
setcount(count+1)


                      
                      // setRef_No('')
                    } else {
                      // setRef_By_Dr('')
                      // setRef_No('')
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Products"
                      variant="outlined"
                      style={{
                        // width: "30em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:width<500?"4%":width<960?"2%":"0%"}}>
            <Autocomplete
                  id="Ref_By_Doctor"

                  options={CategoriesList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.cat_title + ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em'
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={(event, newValue) => {
                    setProductList([])

                    if (newValue != null) {

                      // setRef_By_Dr(newValue.DoctorID)
                      console.log("Ref by Doctor self"+newValue.cat_id)
                      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",
                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

        category_id:newValue.cat_id,
        user_id:localStorage.getItem('user_id')

      })
    }).then((res) => res.json()).then((response) => {
    console.log("check Products response "+JSON.stringify(response))  
    setProductList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    })

                      // setRef_No('')
                    } else {
                      // setRef_By_Dr('')
                      // setRef_No('')
                      // console.log("me chala")
                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

                      {
                        method:"POST",
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                          // branch_id:1,
                          branch_id:parseInt(localStorage.getItem('branch_id')),
                  
                          category_id:0,
                          user_id:localStorage.getItem('user_id')
                  
                        })
                      }).then((res) => res.json()).then((response) => {
                      console.log("check Products response "+JSON.stringify(response))  
                      setProductList(response)
                      //   var obj = {}
                      //   response.map((item) => {
                      //     obj[item.DoctorID] = item.DoctorName
                      //   })
                        // setDoctorNameList(obj)
                  
                      })
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Categories"
                      variant="outlined"
                      style={{
                        // width: "30em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
           
            
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={3} style={{marginTop:width<960?"2%":"0%"}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setOpen(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
<GroupIcon style={{width:20,height:20,color:"white",marginRight:5}}></GroupIcon>
               Select Clients
           </Button>
           {localStorage.getItem('client_Title')?
           <p style={{fontWeight:"bold",marginLeft:"2%"}}>
            Client :
           {localStorage.getItem('client_Title')}</p>
            :<></>}
            </GridItem> */}
            <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop:width<500?"4%":"2%"}}>
              <MaterialTable
                title="Selected Products"
                // columns={columns}
                columns={localStorage.getItem('If_allow_discount')=="true"?columns:columnsNewForCondiotion}

                
                data={cartItems}
                style={{
                  marginLeft:width<960?0: 10
                  
                }}
                
                options={{
                  // paging: false,
                  headerStyle: {
                    backgroundColor: "#5794ca",
                    color: "#FFF",
                    // fontSize: "17px",
                    // textAlign: "center",
                    fontWeight: "bold",
                    // minHeight:"50em"
                  },
                
                  rowStyle: rowData => {
                    // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
                    //   return {};
                    // }
                    // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
                    return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
                  };
                  }
                }}
// minBodyHeight={"25em"}
                maxBodyHeight={"50em"}
                // actions={[
                //   {
                //       icon: "save",
                //       tooltip: "Save User",
                //       onClick: (event, rowData) => alert("You saved " + rowData.name)
                //   }
                //   ]}
                editable={{
                  onRowUpdate: ( newData, oldData) =>
                    new Promise((resolve, reject) => {
                      // setTimeout(async () => {
                        console.log("old data"+JSON.stringify(oldData))
                        console.log("new data"+JSON.stringify(newData))
                        const index = oldData.systemId;
// 1-sep-2024
if(newData.qty >newData.stock){
  alert(newData.itemName+" Item Quantity is greater than Stock !")
}

                        if(oldData.qty==0&&oldData.rfd>0){
                        console.log("check oper rfd"+newData.rfd+"-"+"qty"+newData.qty)

                          newData.rfd=0
                        }
                        if(newData.rfd>0 &&newData.qty!=0){
                        console.log("check niche"+newData.rfd+"-"+"qty"+newData.qty)

                        newData.qty=0
                        // console.log("check rfd"+newData.rfd+"-"+"qty"+newData.qty)
                      }
                      //  if(newData.rfd>0&& newData.qty>0){
                      //   newData.rfd=0
                      // }
                        console.log("index is "+index)
                        const dataUpdate = [...cartItems];
                        console.log("New data is "+JSON.stringify(newData.rfd))
                        resolve()
            dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:newData.qty,rfd:newData.rfd,updatedAmount:(newData.qty*newData.rate).toFixed(2),disc:newData.disc,updatedRfdAmount:(newData.rfd*newData.rate).toFixed(2)})

            dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:newData.qty,updatedAmount:(newData.qty*newData.rate).toFixed(2),rfd:newData.rfd,updatedRfdAmount:(newData.rfd*newData.rate).toFixed(2)})
           // 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
           setSpecialDiscountPer(0)
           setSpecialDiscountValue(0)
            resolve()
            this.forceUpdate();
            
                        resolve()
                     
                        // reject()
                       

                        // const index = oldData.systemId;

            // dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:newData.qty,updatedAmount:(newData.qty*newData.amount)})

                      //   const dataUpdate = [...data];
                      //   const index = oldData.tableData.id;
                      //   if (oldData.title != newData.title && (oldData.Test_ID.includes('SRV'))) {
                      //     dataUpdate[index].title = newData.title;
                      //     setData([...dataUpdate]);
                      //   }
                      //  // Update Require
                      //   if(newData.discount_percent>newData.AllowDiscountPercent){
                      //     alert("Discount Limit of "+newData.Test_Name+" = "+newData.AllowDiscountPercent+"%")
                      //     resolve();
                      //     return
                      //   }
                        
                      //   if (newData.discount_percent != oldData.discount_percent) {
                      //     if (newData.discount_percent > 100) {

                      //     } else {
                      //       var dc_value = (newData.cost / 100) * parseInt(newData.discount_percent)

                      //       var net_cost = (newData.cost - dc_value) - parseInt(newData.special_discount)

                      //       if (net_cost < 0) {
                      //         alert("More Disocunt can't applied!")

                      //         resolve();
                      //         return
                      //       }

                      //       newData.discount_value = dc_value
                      //       newData.net_cost = net_cost

                      //       dataUpdate[index] = newData;
                      //       console.log("2911"+JSON.stringify(dataUpdate[index]))
                      //       console.log("2911"+JSON.stringify( newData))
                      //        setData([...dataUpdate]);
                      //     }
                      //   }

                      //   var cost = 0
                      //   var discount_value = 0
                      //   var sp_disc = 0
                      //   var net_cost = 0
                      //   var temp = await dataUpdate.map((item) => {
                      //     console.log("2920"+item)
                      //     cost = cost + item.cost
                      //     discount_value = discount_value + item.discount_value
                      //     sp_disc = sp_disc + item.special_discount
                      //     return net_cost = net_cost + item.net_cost
                      //   })
                      //   Promise.all(temp).then(() => {
                      //     setTotal_cost(cost)
                      //     setTotalDiscountValue(discount_value)
                      //     setTotalSpecialDiscount(sp_disc)
                      //     setTotalNetCost(net_cost)
                      //     setCC([])
                      //     setBalance_Payment(net_cost)
                      //     setReceived_Payment(0)
                      //   })




                        resolve();
                      // }, 1000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        // const index = oldData.systemId;
                        let index = cartItems.findIndex(x => x.systemId == oldData.systemId);

                        console.log("check delete data"+JSON.stringify(oldData))
                        dispatcchUserEvents("REMOVE_FROM_CART",{data:oldData,deductedAmount:oldData.qty*oldData.netvalue,index})
                        setSpecialDiscountPer(0)
                        setSpecialDiscountValue(0)
                        
                        resolve();

                      }, 1000)
                    }),
                }}
              />
            </GridItem>
            {/* <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Just Booked</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'red', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Only Phlebotomy Done</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'purple', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Lying Pending Results</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} >
              <div style={{
                backgroundColor: 'blue', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Done But not Signed</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} >
              <div style={{
                backgroundColor: 'green', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Ready</p>
            </GridItem> */}

          </GridContainer>
          <GridContainer >
                     
                     <GridItem sm={12} md={12}lg={4} >
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:width<960?0:25
     }}>
       <b>Remarks</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '5em',
           marginLeft: 1
         }}
         value={remarks}
         onChange={(e) => {
           setremarks(e.target.value)
         }}
         placeholder="Transaction Remarks"
       /></span>
                     </GridItem>
                     <GridItem sm={12} md={12} lg={4} style={{marginTop:"2%"}}>
                     {/* <GridItem sm={6} md={6} lg={12}> */}
                     <TextField
            required
            id="outlined-required"
            label="Refund Total $"
            style={{
              borderRadius: 5,
              // width:"22%",
              marginTop:width<500?10:10,

              width:width<500?"48%":"36.8%",
              // marginRight:"4%"
              marginRight:width<500?"4%":"3.2%"
             
            }}
            // value={billAmount}
            value={TotalRfdAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
                     <TextField
            // required
            id="outlined-required"
            label="Refund Discount $"
            style={{
              borderRadius: 5,
              // width:"22%",
              width:width<500?"48%":"36.8%",
              marginTop:width<500?10:10,

              marginRight:width<500?"0%":"3.2%"
              
            }}
            // value={billAmount}
            value={TotalRefundDiscountAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
           <TextField
          // required
          id="outlined-required"
          label="Refund Spec Disc %"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",
            marginTop:width<500?10:10,

            // marginRight:"2%"
            marginRight:width<500?"4%":"3.2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountPer}

          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
          }}
          onChange={(e) => {
              
            if(e.target.value<100){
              console.log("rfdSpecialDiscountPer is"+e.target.value)
              let totalPer =((e.target.value/100)*TotalRfdAmount)
              console.log("totalPer is"+totalPer)
              
             
              let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
                setrfdSpecialDiscountPer(0)
                setrfdSpecialDiscountValue(0)
              }
            else{
      dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount,onChangeValue:e.target.value})
      setrfdSpecialDiscountValue(totalPer.toFixed(2))
     setrfdSpecialDiscountPer(e.target.value)

            }
            
            // setrfdSpecialDiscountPer(e.target.value)
          }else{
            alert("Special Disc must be less than 100")
          }
       
    
          }}
          
        />
         {/* {rfdSpecialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                 
                      if(TotalRfdAmount){

          
          
          console.log("rfdSpecialDiscountPer is"+rfdSpecialDiscountPer)
          let totalPer =((rfdSpecialDiscountPer/100)*TotalRfdAmount)
          console.log("totalPer is"+totalPer)
          
         
          let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount})
  setrfdSpecialDiscountValue(totalPer.toFixed(2))
 setrfdSpecialDiscountPer(rfdSpecialDiscountPer)
        }
                        
                        }
                         else{
                          alert("no refund Products show ")
                         }
                 






                  }
                }>Apply</Button>} */}
              <TextField
          // required   
          id="outlined-required"
          label="Refund Spec Disc $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",
            marginTop:width<500?10:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountValue}

          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
          }}
          onChange={(e) => {
              
            // if(e.target.value<100){
              let totalPercentage =((e.target.value*100)/TotalRfdAmount)

              let totalPer =((totalPercentage/100)*TotalRfdAmount)
              console.log("totalPer is"+totalPer)
              
             
              let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
                setrfdSpecialDiscountPer(0)
                setrfdSpecialDiscountValue(0)
              }
            else{
      dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount,onChangeValue:e.target.value})
      setrfdSpecialDiscountValue(e.target.value)
     setrfdSpecialDiscountPer(totalPercentage.toFixed(2))

            }
            
            
            // setrfdSpecialDiscountValue(e.target.value)
          // }else{
          //   alert("Special Disc must be less than 100")
          // }
       
    
          }}
          
        />
                    
         
                     <TextField
            // required
            id="outlined-required"
            label="Refund Net Val $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
              marginRight:width<500?"0%":"3.2%",

              marginTop:width<500?10:0,

              marginTop:10,
              // marginLeft:5
            }}
            // value={TotalRfdPayable}
            value={TotalRfdPayableAddDisc}

           
            type="number"
           
            variant="outlined"
             InputProps={{
            readOnly: true,
          }}
          />
             {/* next four */}
                     {/* <TextField
            // required
            id="outlined-required"
            label="Amount $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,
              // marginLeft:5
            }}
            value={TotalAmount}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
       
           <TextField
          // required
          id="outlined-required"
          label="Discount $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            marginTop:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.1%",

            // marginTop:6,
            // marginLeft:5
          }}
          value={TotalDiscount}
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount per %"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountPer}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              
              if(e.target.value<100){

              
              // setSpecialDiscountPer(e.target.value)
              let totalVal =(TotalAmount-e.target.value)
              let totalPer =((e.target.value/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
              setSpecialDiscountValue(totalPer.toFixed(2))
              console.log("special discount amount is"+totalPer)
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              console.log("check TotalDiscount+totalPerAmount"+(TotalDiscount+totalPerAmount))
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount,onChangeValue:e.target.value})

              // }
              }
     setSpecialDiscountPer(e.target.value)
            }
            }else{
              alert("Special Disc must be less than 100")
            }
         
      //         if(netValue>0){
      //    var total= ((discount)/(100))*netValue
      //    setnetValue(netValue-total)
      //     }
            }}
          />  
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount Value $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountValue}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              // 
              let totalPercentage =((e.target.value*100)/TotalAmount)
              console.log("total percent is "+totalPer)
              // let totalVal =(TotalAmount-e.target.value)
              let totalPer =((totalPercentage/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
             
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total Amount")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountPer:totalPerAmount,onChangeValue:totalPercentage})

              // }
              }
            setSpecialDiscountPer(totalPercentage.toFixed(2))

    
            }
            setSpecialDiscountValue(e.target.value)



             
              console.log("check "+e.target.value)
              
         
            }}
          />  
         
          
          
          <TextField
          // required
          id="outlined-required"
          label="Payable $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

            marginTop:10,

            // marginTop:6,
            // marginLeft:5
          }}
          // value={TotalPayable}
          value={TotalPayableAddDisc}
          
          
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        /> */}
                   
           {/* </GridItem> */}
                     </GridItem>
                     <GridItem sm={12} md={12} lg={4} style={{marginTop:"2%"}}>
                     {/* <GridItem sm={6} md={6} lg={12}> */}
                     {/* <TextField
            required
            id="outlined-required"
            label="Refund Total"
            style={{
              borderRadius: 5,
              // width:"22%",
              marginTop:width<500?10:0,

              width:width<500?"48%":"22%",
              // marginRight:"4%"
              marginRight:width<500?"4%":"2%"
             
            }}
            // value={billAmount}
            value={TotalRfdAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
                     <TextField
            required
            id="outlined-required"
            label="Refund Discount"
            style={{
              borderRadius: 5,
              // width:"22%",
              width:width<500?"48%":"22%",
              marginTop:width<500?10:0,

              marginRight:width<500?"0%":"2%"
              
            }}
            // value={billAmount}
            value={TotalRefundDiscountAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
           <TextField
          required
          id="outlined-required"
          label="Refund Spec Disc"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"22%",
            marginTop:width<500?10:0,

            // marginRight:"2%"
            marginRight:width<500?"4%":"2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountPer}

          type="number"
         
          variant="outlined"
          onChange={(e) => {
              
            if(e.target.value<100){

            
            setrfdSpecialDiscountPer(e.target.value)
          }else{
            alert("Special Disc must be less than 100")
          }
       
    //         if(netValue>0){
    //    var total= ((discount)/(100))*netValue
    //    setnetValue(netValue-total)
    //     }
          }}
          
        />
         {rfdSpecialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                    // if(cartItems>0){
                      if(TotalRfdAmount){

          
          // let totalVal =(TotalAmount-specialDiscountPer)
          console.log("rfdSpecialDiscountPer is"+rfdSpecialDiscountPer)
          let totalPer =((rfdSpecialDiscountPer/100)*TotalRfdAmount)
          console.log("totalPer is"+totalPer)
          
          // total kitna special discount hua ye save kiya hai send krna hai 
          // setSpecialDiscountValue(totalPer)
          // console.log("special discount amount is"+totalPer)
          let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount})
  setrfdSpecialDiscountValue(totalPer.toFixed(2))
 setrfdSpecialDiscountPer(rfdSpecialDiscountPer)
        }
                        
                        }
                         else{
                          alert("no refund Products show ")
                         }
                    // }
                    // else{
                    //   alert("Please select Products ")
                    //   // setdiscount(0)
                    // }






                  }
                }>Apply</Button>}
          
                    
         
                     <TextField
            required
            id="outlined-required"
            label="Refund Net Value"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"22%",

              // marginRight:"2%",
              marginRight:width<500?"0%":"2%",

              marginTop:width<500?10:0,

              // marginTop:6,
              // marginLeft:5
            }}
            value={TotalRfdPayable}
            type="number"
           
            variant="outlined"
             InputProps={{
            readOnly: true,
          }}
          /> */}
             {/* next four */}
                     <TextField
            // required
            id="outlined-required"
            label="Amount $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,
              // marginLeft:5
            }}
            value={TotalAmount}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
       
           <TextField
          // required
          id="outlined-required"
          label="Discount $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            marginTop:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.1%",

            // marginTop:6,
            // marginLeft:5
          }}
          value={TotalDiscount}
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount per %"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountPer}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
            }}
            onChange={(e) => {
              
              if(e.target.value<100){

              
              // setSpecialDiscountPer(e.target.value)
              let totalVal =(TotalAmount-e.target.value)
              let totalPer =((e.target.value/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
              console.log("special discount amount is"+totalPer)
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              console.log("check TotalDiscount+totalPerAmount"+(TotalDiscount+totalPerAmount))
              if(TotalDiscount+totalPerAmount>TotalAmount){
                // alert("do not apply more than Total")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount,onChangeValue:e.target.value})

              // }
              }
              setSpecialDiscountValue(totalPer.toFixed(2))

     setSpecialDiscountPer(e.target.value)
            }
            }else{
              alert("Special Disc must be less than 100")
            }
         
      //         if(netValue>0){
      //    var total= ((discount)/(100))*netValue
      //    setnetValue(netValue-total)
      //     }
            }}
          />  
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount Val $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountValue}
            type="number"
            InputProps={{
              readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
            }}
            variant="outlined"
            onChange={(e) => {
              // 
              let totalPercentage =((e.target.value*100)/TotalAmount)
              console.log("total percent is "+totalPer)
              // let totalVal =(TotalAmount-e.target.value)
              let totalPer =((totalPercentage/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
             
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total Amount")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountPer:totalPerAmount,onChangeValue:totalPercentage})

              // }
              }
            setSpecialDiscountPer(totalPercentage.toFixed(2))
            setSpecialDiscountValue(e.target.value)

    
            }



             
              console.log("check "+e.target.value)
              
         
            }}
          />  
            {/* {specialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                    // if(cartItems>0){
                      if(TotalAmount){

          let totalVal =(TotalAmount-specialDiscountPer)
          let totalPer =((specialDiscountPer/100)*TotalAmount)
          // total kitna special discount hua ye save kiya hai send krna hai 
          setSpecialDiscountValue(totalPer.toFixed(2))
          console.log("special discount amount is"+totalPer)
          let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount})
 setSpecialDiscountPer(specialDiscountPer)
        }
                        
                        }
                         else{
                          alert("Please Select Products ")
                         }
                  }
                }>Apply</Button>} */}
          
          
          <TextField
          // required
          id="outlined-required"
          label="Payable $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

            marginTop:10,

            // marginTop:6,
            // marginLeft:5
          }}
          // value={TotalPayable}
          value={TotalPayableAddDisc}
          
          
          type="number"
         
          variant="outlined"
          
          InputProps={{
            readOnly: true,
          }}
        />
                   
           {/* </GridItem> */}
                     </GridItem>
                     </GridContainer>
                     <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={6}></GridItem>
                     <GridItem sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
            // alert("check data "+remarks+from.toISOString())
            window.location.reload();

            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "12.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#cb1a2d"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
             Cancel Order
           </Button>
           <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
              //imp point refund_special_discount_percent:"", ye pdf me discunt hai
              if (localStorage.getItem('client_id')){
                if(cartItems.length!=0){
              var ArrEntrySequence=[]
              var ArrSequenceNo=[]
              var ArrItemSystemID=[]
              var ArrItemCode=[]
              var ArrItemName=[]
              var ArrItemQty=[]
              var ArrItemRate=[]
              var ArrItemAmount=[]
              var ArrItemUnitCost=[]
              var ArrDiscountPercent=[]
              var ArrDiscountValue=[]
              var ArrRefundQty=[]
              var ArrRefundValue=[]

              for(let i=cartItems.length-1;i>=0;i--){
              // for(let i=0;i<cartItems.length;i++){

                        console.log("check array result"+ JSON.stringify(cartItems[i]))
                        ArrEntrySequence.push(cartItems[i].sn)
                        ArrSequenceNo.push(cartItems[i].sn)
                        ArrItemSystemID.push(cartItems[i].systemId)
                        ArrItemCode.push(cartItems[i].code)
                        ArrItemName.push(cartItems[i].itemName)
                        ArrItemQty.push(cartItems[i].qty)
                        ArrItemRate.push(cartItems[i].rate)
                        ArrItemAmount.push(cartItems[i].amount)
                        // yhan pe net value ki jga buyingPrice aaega 
                        ArrItemUnitCost.push(parseFloat((cartItems[i].netvalue)))
                        ArrDiscountPercent.push(cartItems[i].disc)
                        ArrDiscountValue.push(parseFloat(cartItems[i].discount))
                        ArrRefundQty.push(cartItems[i].rfd)
                        ArrRefundValue.push(parseFloat((cartItems[i].rfdAmount)))


                        // ArrayInvoiceNo.push(totalinvoice[i].id)
                        // ArrayInvoiceOutstanding.push(totalinvoice[i].payable)
                        // ArrayInvoiceReceived.push(totalinvoice[i].receivedValue)
              }
              //client id        01CL00724
              // client title    1 10 MART
            let data={
              invoice_no:"",
              branch_id:parseInt(localStorage.getItem('branch_id')),
              invoice_date:from.toISOString(),
              invoice_remarks:remarks,
              user_id:localStorage.getItem('user_id'),
              user_name:localStorage.getItem('user_name'),
              client_id:localStorage.getItem('client_id'),
              client_title:localStorage.getItem('client_Title'),
              inv_amount:parseFloat(TotalAmount),
              inv_discount_value:TotalDiscount,
              inv_discount_special_percent:parseFloat(specialDiscountPer)?parseFloat(specialDiscountPer):0,
              inv_discount_special_value:parseFloat(specialDiscountValue)?parseFloat(specialDiscountValue):0,
              // totalPayable ab change hai while jo ab hai wo dalna hai
              // invoice_net_value:parseFloat(TotalPayable),
              invoice_net_value:parseFloat(TotalPayableAddDisc),

              refund_amount:parseFloat(TotalRfdAmount),
              refund_line_discount:TotalRefundDiscountAmount,
              refund_special_discount_percent:parseFloat(rfdSpecialDiscountPer),
              refund_special_discount_value:parseFloat(rfdSpecialDiscountValue),
              refund_net_value:parseFloat(TotalRfdPayable),
              ArrEntrySequence:ArrEntrySequence,
              ArrSequenceNo:ArrSequenceNo,
              ArrItemSystemID:ArrItemSystemID,
              ArrItemCode:ArrItemCode,
              ArrItemName:ArrItemName,
              ArrItemQty:ArrItemQty,
              ArrItemRate:ArrItemRate,
              ArrItemAmount:ArrItemAmount,
              ArrItemUnitCost:ArrItemUnitCost,
              ArrDiscountPercent:ArrDiscountPercent,
              ArrDiscountValue:ArrDiscountValue,
              ArrRefundQty:ArrRefundQty,
              ArrRefundValue:ArrRefundValue

            }
            console.log("check obj "+JSON.stringify(data))
            // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceSavingUpdating",
            fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceSavingUpdating",

            {
              method:"POST",
              'Content-Type':"application/json",
              body: JSON.stringify(data)
            }).then((res) => res.json()).then((response) => {
         
         
            localStorage. removeItem('client_id')
          localStorage. removeItem('client_Title')
          alert("Invoice Save Successfully !")
          setSpecialDiscountPer(0)
          setSpecialDiscountValue(0)
          setrfdSpecialDiscountPer(0)
          setrfdSpecialDiscountValue(0)
dispatcchUserEvents("ClearData")
     
            })
          }
          else{
            alert("Please Select Products")

          }
            }else{
              alert("Please Select Client")
            }
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "12.5em",
             padding: "5px",
             height: "4em",
             marginLeft:"2%",
            //  borderRadius:30
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
               Save Record
           </Button>
           </GridItem>
           </GridContainer>

        </div>
        
       
       


      </Paper>
     
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOpen(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h3 className={classes.modalTitle}><b>Please Select a Client</b></h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <MaterialTable
            columns={[
              { field: "select", title: "Select" ,
             },
              { field: "clientTitle", title: "Client's Title",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.clientTitle}</p>
              } },
              { field: "phone", title: "Phone",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.phone}</p>
              }},
              { field: "city", title: "City",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.city}</p>
              } },
              { field: "state", title: "State",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.state}</p>
              } },
              { field: "zip", title: "Zip",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.zip}</p>
              } },
              { field: "address", title: "Address",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.address}</p>
              } },
             



            ]}
            options={{
              // paging: false,
              headerStyle: {
                backgroundColor: "#5794ca",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={ClientList}
            title={"Clients"}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOpen(false)
              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={openImageView}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setopenImageView(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h3 className={classes.modalTitle}><b>View Image</b></h3>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
           <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={12} >
                     {imageloading?  <center>
            <ReactLoading type={'spinningBubbles'} color={'#196c90'} height={'10em'} width={'10em'} />
            <h3>Please Wait Image Loading....</h3>
          </center>:
                      <div style={{  display:"flex",justifyContent:"center",alignItems:"center",}}>
                        <img src={selectedReport}></img>
                     {/* <iframe  style={{alignSelf:"center"}}  src={selectedReport}  width={500} height={400}></iframe> */}
                     </div>}
                     </GridItem>
                     </GridContainer>


        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenImageView(false)
              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
